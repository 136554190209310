<template>
  <v-toolbar>
    <v-toolbar-title>{{ title }}</v-toolbar-title>
    <v-spacer></v-spacer>
    <template v-if="isSmall">
      <v-btn icon @click="toggleFilter">
        <v-icon>fal fa-search</v-icon>
      </v-btn>
      <v-menu :close-on-content-click="false">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon>
            <v-icon>fal fa-cog</v-icon>
          </v-btn>
        </template>
        <v-list style="min-width:260px">
          <v-subheader>View List As</v-subheader>
          <view-menu :expanded="true"></view-menu>
          <group-menu :groupable="groupable" :search-field="field" :isButton="false"></group-menu>
          <column-menu :isButton="false"></column-menu>
          <sort-menu :view="view" :is-button="false" />
        </v-list>
      </v-menu>
    </template>
    <template v-else>
      <column-menu></column-menu>
      <v-menu v-if="JSON.stringify(query) !== '{}' || people.length > 0 || groupedPeople.length > 0" offset-y>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" text>
            Export
            <v-icon right>fal fa-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item @click="exportList('xlsx')">
            <v-list-item-content>
              <v-list-item-title>Export to Excel</v-list-item-title>
              <v-list-item-subtitle>Will export a list of data; will not include photos</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <!-- <v-list-item disabled @click="exportList('pdf')">
            <v-list-item-content>
              <v-list-item-title>Export to PDF</v-list-item-title>
              <v-list-item-subtitle>Coming soon; will mimic the current layout</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item> -->
          <v-dialog v-if="view === 'list'" v-model="printDialog" width="500">
            <template v-slot:activator="{ on }">
              <v-list-item v-on="on" :disabled="!printable">
                <v-list-item-content>
                  <v-list-item-title>Print List</v-list-item-title>
                  <v-list-item-subtitle v-if="printable">Opens a dialog where you can choose what fields to print</v-list-item-subtitle>
                  <v-list-item-subtitle v-else>The current list is not printable</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
            <v-card>
              <v-toolbar>
                <v-toolbar-title>Options for printing your list</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="printDialog = false">
                  <v-icon>fal fa-times</v-icon>
                </v-btn>
              </v-toolbar>
              <v-card-text>
                <v-switch v-model="printHeadersInGroups" :label="printHeadersInGroups ? 'Print column headers within each group' : 'Print column header only at the top of the page'"></v-switch>
                <p class="mt-4">Choose what fields you want to print</p>
                <!-- <v-select v-model="printFields" :items="printFieldOptions" label="Fields to print" chips small-chips deletable-chips multiple outlined></v-select> -->
                <v-row>
                  <v-col>
                    <v-list elevation="1" class="pa-0">
                      <v-subheader>Available Fields. Click to add.</v-subheader>
                      <v-list-item v-for="{ text, value } in excludedPrintFieldOptions" :key="value" dense style="border-top: 1px solid #CCC; border-bottom: 1px solid #CCC" @click="togglePrintField(value)">
                        <v-list-item-title>{{ text }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-col>
                  <v-col>
                    <v-list elevation="1" class="pa-0">
                      <v-subheader>Included Fields. Drag to change, click X to remove.</v-subheader>
                      <draggable v-model="printFields">
                        <v-list-item v-for="value in printFields" :key="value" dense style="border-top: 1px solid #CCC; border-bottom: 1px solid #CCC">
                          <v-list-item-avatar class="mt-0 mb-0" style="min-width:20px;max-width:20px">
                            <v-icon small>fas fa-bars</v-icon>
                          </v-list-item-avatar>
                          <v-list-item-title>{{ printFieldOptions.filter(({ value: val }) => value === val)[0].text }}</v-list-item-title>
                          <v-list-item-action>
                            <v-btn small icon @click="togglePrintField(value)">
                              <v-icon color="error" small>fal fa-times-circle</v-icon>
                            </v-btn>
                          </v-list-item-action>
                        </v-list-item>
                      </draggable>
                    </v-list>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-btn text @click="printDialog = false">Cancel</v-btn>
                <v-btn text color="success" @click="exportList('print')">
                  <v-icon left>fal fa-print</v-icon>
                  Print List
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-list-item v-else :disabled="!printable" @click="exportList('print')">
            <v-list-item-content>
              <v-list-item-title>Print List</v-list-item-title>
              <v-list-item-subtitle v-if="printable">Opens in a new window; will mimic the current layout</v-list-item-subtitle>
              <v-list-item-subtitle v-else>The current list is not printable</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn v-else-if="printable" text @click="exportList('print')">
        <v-icon left>fal fa-print</v-icon> Print
      </v-btn>
      <sort-menu :view="view" />
      <group-menu :groupable="groupable" :search-field="field"></group-menu>
      <view-menu></view-menu>
    </template>
  </v-toolbar>
</template>
<style scoped>
  @media print {
    header {
      box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important
    }
    button {
      display: none !important
    }
  }
</style>
<script>
import { computed, ref } from '@vue/composition-api'
import { saveAs } from 'file-saver'

export default {
  components: {
    ColumnMenu: () => import('./header/columnMenu'),
    SortMenu: () => import('./header/sortMenu'),
    GroupMenu: () => import('./header/groupMenu'),
    ViewMenu: () => import('./header/viewMenu'),
    draggable: () => import('vuedraggable')
  },
  props: {
    title: {
      type: String,
      default: 'GreatScots Campus Directory'
    },
    groupable: {
      type: Boolean,
      default: true
    },
    printable: {
      type: Boolean,
      default: false
    },
    owner: {
      type: Boolean,
      default: false
    },
    field: String,
    view: {
      type: String,
      default: 'list'
    },
    query: {
      type: Object,
      default: () => { return {} }
    },
    people: {
      type: Array,
      default: () => { return [] }
    },
    groupedPeople: {
      type: Array,
      default: () => { return [] }
    }
  },
  setup (props, { root, emit }) {
    async function exportList (format) {
      switch (format) {
        case 'xlsx':
          const columns = {
            dep: true,
            phone: false,
            hall: false,
            room: false,
            box: true,
            job: true
          }
          if ('settings' in root.$store.state.user && 'directoryColumns' in root.$store.state.user.settings) {
            const dirCols = root.$store.state.user.settings.directoryColumns
            for (let l in dirCols) {
              columns[l] = dirCols[l]
            }
          }
          const service = root.$feathers.service('directory/people')
          const query = Object.assign({}, props.query)
          if (!('aggregate' in query)) query.$limit = 0
          const { total, data } = await service.find({ query })
          const header = [
            { header: 'First Name', align: 'left', key: 'first' },
            { header: 'Last Name', align: 'left', key: 'last' },
            { header: 'Category', align: 'left', key: 'person' },
            { header: 'Email', align: 'left', key: 'email' }
          ]
          query.$select = ['name', 'person', 'email']
          if (columns.dep) {
            query.$select.push('location.department')
            header.push({ header: 'Department', align: 'left', key: 'department' })
          }
          if (columns.phone) {
            query.$select.push('phone')
            header.push({ header: 'Phone Number', align: 'left', key: 'phone' })
          }
          if (columns.hall) {
            query.$select.push('location.hall')
            header.push({ header: 'Hall', align: 'left', key: 'hall' })
          }
          if (columns.room) {
            query.$select.push('location.room')
            header.push({ header: 'Office/Room', align: 'left', key: 'room' })
          }
          if (columns.box) {
            query.$select.push('location.box')
            header.push({ header: 'Box Number', align: 'right', key: 'box' })
          }
          if (columns.job) {
            query.$select.push('jobTitle')
            header.push({ header: 'Job Title', align: 'left', key: 'jobTitle' })
          }
          let rows = []
          if ('aggregate' in query) {
            buildExportRows(data, rows)
          } else {
            query.$limit = 50
            for (let i = 0; i < total; i += 50) {
              query.$skip = i
              const { data } = await service.find({ query })
              buildExportRows(data, rows)
            }
          }
          const filename = props.title + '.xlsx'
          root.$feathers.service('export/xlsx').create({ filename, header, rows }).then((data) => {
            saveAs(new Blob([data.buffer]), filename)
          })
          break
        case 'pdf':
          break
        case 'print':
          emit('print', { fields: printFields.value, printHeadersInGroups: printHeadersInGroups.value })
          setTimeout(() => { printDialog.value = false }, 3000)
          break
      }
    }

    const printDialog = ref(false)
    const printHeadersInGroups = ref(false)
    const printFieldOptions = ref([
      { text: 'Photo', value: 'photo' },
      { text: 'Last Name', value: 'name.last' },
      { text: 'Preferred', value: 'name.first' },
      { text: 'Category', value: 'person' },
      { text: 'Email', value: 'email' },
      { text: 'Department', value: 'location.department' },
      { text: 'Phone', value: 'phone' },
      { text: 'Hall', value: 'location.hall' },
      { text: 'Office/Room', value: 'location.room' },
      { text: 'Box Number', value: 'location.box' },
      { text: 'Job Title', value: 'jobTitle' }
    ])
    const printFields = ref(['photo', 'name.last', 'name.first', 'person', 'email'])
    const includedPrintFieldOptions = computed(() => printFieldOptions.value.filter(({ value }) => printFields.value.includes(value)))
    const excludedPrintFieldOptions = computed(() => printFieldOptions.value.filter(({ value }) => !printFields.value.includes(value)))
    function togglePrintField (field) {
      if (printFields.value.includes(field)) {
        for (let i = 0; i < printFields.value.length; i++) {
          if (printFields.value[i] === field) {
            printFields.value.splice(i, 1)
            break
          }
        }
      } else {
        printFields.value.push(field)
      }
    }

    function buildExportRows (data, rows) {
      for (const row of data) {
        row.first = row.name.first
        row.last = row.name.last
        if ('location' in row) {
          row.box = row.location.box
          row.room = row.location.room
          row.department = row.location.department
          row.hall = row.location.hall
        }
        rows.push(row)
      }
    }

    function toggleFilter () {
      root.$store.commit('greatscots/toggleFilterActive')
    }

    const isSmall = computed(() => root.$vuetify.breakpoint.name === 'xs' || root.$vuetify.breakpoint.name === 'sm')

    return {
      exportList,
      printDialog,
      printHeadersInGroups,
      printFieldOptions,
      printFields,
      includedPrintFieldOptions,
      excludedPrintFieldOptions,
      togglePrintField,
      toggleFilter,
      isSmall
    }
  }
}
</script>
