var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-toolbar',[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.title))]),_c('v-spacer'),(_vm.isSmall)?[_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.toggleFilter}},[_c('v-icon',[_vm._v("fal fa-search")])],1),_c('v-menu',{attrs:{"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("fal fa-cog")])],1)]}}],null,false,3735234880)},[_c('v-list',{staticStyle:{"min-width":"260px"}},[_c('v-subheader',[_vm._v("View List As")]),_c('view-menu',{attrs:{"expanded":true}}),_c('group-menu',{attrs:{"groupable":_vm.groupable,"search-field":_vm.field,"isButton":false}}),_c('column-menu',{attrs:{"isButton":false}}),_c('sort-menu',{attrs:{"view":_vm.view,"is-button":false}})],1)],1)]:[_c('column-menu'),(JSON.stringify(_vm.query) !== '{}' || _vm.people.length > 0 || _vm.groupedPeople.length > 0)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":""}},on),[_vm._v(" Export "),_c('v-icon',{attrs:{"right":""}},[_vm._v("fal fa-chevron-down")])],1)]}}],null,false,1189345477)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.exportList('xlsx')}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Export to Excel")]),_c('v-list-item-subtitle',[_vm._v("Will export a list of data; will not include photos")])],1)],1),(_vm.view === 'list')?_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item',_vm._g({attrs:{"disabled":!_vm.printable}},on),[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Print List")]),(_vm.printable)?_c('v-list-item-subtitle',[_vm._v("Opens a dialog where you can choose what fields to print")]):_c('v-list-item-subtitle',[_vm._v("The current list is not printable")])],1)],1)]}}],null,false,186794588),model:{value:(_vm.printDialog),callback:function ($$v) {_vm.printDialog=$$v},expression:"printDialog"}},[_c('v-card',[_c('v-toolbar',[_c('v-toolbar-title',[_vm._v("Options for printing your list")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.printDialog = false}}},[_c('v-icon',[_vm._v("fal fa-times")])],1)],1),_c('v-card-text',[_c('v-switch',{attrs:{"label":_vm.printHeadersInGroups ? 'Print column headers within each group' : 'Print column header only at the top of the page'},model:{value:(_vm.printHeadersInGroups),callback:function ($$v) {_vm.printHeadersInGroups=$$v},expression:"printHeadersInGroups"}}),_c('p',{staticClass:"mt-4"},[_vm._v("Choose what fields you want to print")]),_c('v-row',[_c('v-col',[_c('v-list',{staticClass:"pa-0",attrs:{"elevation":"1"}},[_c('v-subheader',[_vm._v("Available Fields. Click to add.")]),_vm._l((_vm.excludedPrintFieldOptions),function(ref){
var text = ref.text;
var value = ref.value;
return _c('v-list-item',{key:value,staticStyle:{"border-top":"1px solid #CCC","border-bottom":"1px solid #CCC"},attrs:{"dense":""},on:{"click":function($event){return _vm.togglePrintField(value)}}},[_c('v-list-item-title',[_vm._v(_vm._s(text))])],1)})],2)],1),_c('v-col',[_c('v-list',{staticClass:"pa-0",attrs:{"elevation":"1"}},[_c('v-subheader',[_vm._v("Included Fields. Drag to change, click X to remove.")]),_c('draggable',{model:{value:(_vm.printFields),callback:function ($$v) {_vm.printFields=$$v},expression:"printFields"}},_vm._l((_vm.printFields),function(value){return _c('v-list-item',{key:value,staticStyle:{"border-top":"1px solid #CCC","border-bottom":"1px solid #CCC"},attrs:{"dense":""}},[_c('v-list-item-avatar',{staticClass:"mt-0 mb-0",staticStyle:{"min-width":"20px","max-width":"20px"}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-bars")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.printFieldOptions.filter(function (ref) {
	var val = ref.value;

	return value === val;
})[0].text))]),_c('v-list-item-action',[_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.togglePrintField(value)}}},[_c('v-icon',{attrs:{"color":"error","small":""}},[_vm._v("fal fa-times-circle")])],1)],1)],1)}),1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.printDialog = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"text":"","color":"success"},on:{"click":function($event){return _vm.exportList('print')}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("fal fa-print")]),_vm._v(" Print List ")],1)],1)],1)],1):_c('v-list-item',{attrs:{"disabled":!_vm.printable},on:{"click":function($event){return _vm.exportList('print')}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Print List")]),(_vm.printable)?_c('v-list-item-subtitle',[_vm._v("Opens in a new window; will mimic the current layout")]):_c('v-list-item-subtitle',[_vm._v("The current list is not printable")])],1)],1)],1)],1):(_vm.printable)?_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.exportList('print')}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("fal fa-print")]),_vm._v(" Print ")],1):_vm._e(),_c('sort-menu',{attrs:{"view":_vm.view}}),_c('group-menu',{attrs:{"groupable":_vm.groupable,"search-field":_vm.field}}),_c('view-menu')]],2)}
var staticRenderFns = []

export { render, staticRenderFns }